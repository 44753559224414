// src/components/charts/WeeklyCallChart.tsx

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface WeeklyCallChartProps {
  weeklyData: { day: string; calls: number; minutes: number }[];
}

const WeeklyCallChart: React.FC<WeeklyCallChartProps> = ({ weeklyData }) => {
  return (
    <Card sx={{ width: '100%', marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Weekly Calls
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={weeklyData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend />
            <Bar
              yAxisId="left"
              dataKey="calls"
              fill="#8884d8"
              name="Calls"
              barSize={20}
            />
            <Bar
              yAxisId="right"
              dataKey="minutes"
              fill="#82ca9d"
              name="Minutes"
              barSize={20}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default WeeklyCallChart;
