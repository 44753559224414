// src/utils/agentDataAdapter.ts

import { AgentTemplate, AgentData, Skill } from '@/types/AgentTypes';
import { BackendAgentTemplate, BackendAgentData, BackendSkill } from '@/types/BackendTypes';

/**
 * Adapts a backend agent template to the frontend AgentTemplate interface.
 * @param backendTemplate The backend agent template.
 * @returns The adapted AgentTemplate.
 */
export function adaptAgentTemplate(backendTemplate: BackendAgentTemplate): AgentTemplate {
  return {
    name: backendTemplate.name || '',
    role: backendTemplate.role || '',
    skill: adaptSkill(backendTemplate.skill),
    generalKnowledge: backendTemplate.general_knowledge || '',
    personality: adaptPersonality(backendTemplate.personality),
    highlight: backendTemplate.highlight || '',
    organizationId: backendTemplate.organization_id || null,
    profileImageUrl: backendTemplate.profile_image_url || '',
  };
}

/**
 * Adapts backend agent data to the frontend AgentData interface.
 * @param backendData The backend agent data.
 * @returns The adapted AgentData.
 */
export function adaptAgentData(backendData: BackendAgentData): AgentData {
  return {
    ...backendData,
    skills: adaptSkill(backendData.skill),
    personality: adaptPersonality(backendData.personality),
    // Map other fields as necessary
  };
}

/**
 * Adapts backend skill data to the frontend Skill interface.
 * @param skill The backend skill data.
 * @returns The adapted Skill array.
 */
function adaptSkill(skill: BackendSkill[] | string): Skill[] {
  if (Array.isArray(skill)) {
    return skill.map((s: BackendSkill) => ({
      id: s.id,
      name: s.name,
      category: s.category,
      scenario: s.scenario,
    }));
  }
  if (typeof skill === 'string') {
    return [{
      id: skill.substring(0, 20),
      name: skill,
      category: 'General',
    }];
  }
  return [];
}

/**
 * Adapts backend personality data to the frontend personality format.
 * @param personality The backend personality data.
 * @returns The adapted personality object.
 */
function adaptPersonality(personality: Record<string, number> | string): { [key: string]: number } {
  if (typeof personality === 'object' && personality !== null) {
    return personality as Record<string, number>;
  }
  if (typeof personality === 'string') {
    return Object.fromEntries(
      personality.split(',').map(trait => {
        const [key, value] = trait.trim().split(':').map(s => s.trim());
        return [key, Number(value)];
      })
    );
  }
  return {};
}
