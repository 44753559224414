// src/components/agentprofile/AgentProfile.tsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Tab,
  Box,
  Snackbar,
  Alert,
  CssBaseline,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  fetchAgentData,
  updateAgentData,
  deleteAssistant,
  updateAssistantProfileImage,
} from '../../api/assistant';
import {
  fetchFileIds,
  fetchFileDetails,
  uploadFile,
  deleteFile,
} from '../../api/file';
import { fetchVoicesFromDB } from '../../api/voice';
import { fetchUserMetrics, fetchAndStoreMetrics } from '../../api/billing';
import { useTheme as useCustomTheme } from '@/components/ThemeContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AgentProfileVoices from './AgentProfileVoices';
import { ChartsSection } from './AgentProfileCharts';
import AgentBasicInfo from './AgentBasicInfo';
import AgentKnowledgeBase from './AgentKnowledgeBase';
import RecentActivityFeed from './RecentActivityFeed';
import {
  FormData,
  Voice,
  FileDetails,
  UserMetrics,
  AgentProfileProps,
} from '@/types/AgentTypes';
import ReactConfetti from 'react-confetti';
import { StyledContainer, StyledPaper, StyledTabs } from './StyledComponents';
import { fetchUserAssistants } from '@/api/user';
import { DBVoice } from '@/types/VoiceTypes';

const AgentProfile: React.FC<AgentProfileProps> = ({ assistantId }) => {
  const { isDarkMode } = useCustomTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData | null>(null);
  const [metrics, setMetrics] = useState<UserMetrics[] | null>(null);
  const [voices, setVoices] = useState<Voice[]>([]);
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);

  const handleImageUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        try {
          const updatedAssistant = await updateAssistantProfileImage(assistantId, file);
          setFormData((prevData) =>
            prevData
              ? { ...prevData, profileImageUrl: updatedAssistant.profile_image_url || '' }
              : null
          );
          window.dispatchEvent(
            new CustomEvent('assistantProfileUpdated', {
              detail: {
                assistantId,
                profileImageUrl: updatedAssistant.profile_image_url,
              },
            })
          );
        } catch (error) {
          console.error('Error uploading profile image:', error);
          setError('Failed to upload profile image');
        }
      }
    },
    [assistantId]
  );

  const fetchAssistantData = useCallback(async () => {
    if (!assistantId) {
      setError('No assistant ID provided');
      return;
    }

    setLoading(true);
    try {
      const data = await fetchAgentData(assistantId);
      if (data) {
        setFormData({
          name: data.name || '',
          generalKnowledge: data.generalKnowledge || '',
          skills: data.skills || [],
          personality: data.personality || {},
          highlights: data.highlight || '',
          knowledgeBase: '',
          voice: data.voiceId || '',
          gender: data.gender || 'female',
          profileImageUrl: data.profileImageUrl || '',
        });
      } else {
        setError('No data received for assistant');
      }
    } catch (err) {
      console.error('Error fetching assistant data: ', err);
      setError(
        'Failed to fetch assistant data: ' +
          (err instanceof Error ? err.message : String(err))
      );
    } finally {
      setLoading(false);
    }
  }, [assistantId]);

  const fetchMetricsData = useCallback(async () => {
    try {
      await fetchAndStoreMetrics(assistantId);
      const metricsData = await fetchUserMetrics(assistantId);
      const adaptedMetrics: UserMetrics[] = metricsData.map(metric => ({
        userId: metric.userId,
        assistantId: metric.assistantId,
        totalDuration: metric.totalDuration,
        totalCost: metric.totalCost,
        callCount: metric.callCount,
        date: metric.date,
      }));
      setMetrics(adaptedMetrics);
    } catch (err) {
      console.error('Error fetching metrics: ', err);
      setError('Failed to fetch metrics. Please try again later.');
    }
  }, [assistantId]);

  const fetchAssistantPhoneNumber = useCallback(async () => {
    try {
      const assistants = await fetchUserAssistants();
      const assistant = assistants.find((a) => a.assistantId === assistantId);
      if (assistant && assistant.phoneNumbers) {
        const numbers = Array.isArray(assistant.phoneNumbers)
          ? assistant.phoneNumbers
          : [assistant.phoneNumbers];
        setPhoneNumbers(numbers);
      }
    } catch (err) {
      console.error('Error fetching assistant phone number: ', err);
      setError('Failed to fetch phone number');
    }
  }, [assistantId]);

  const fetchVoicesData = useCallback(async () => {
    try {
      const voicesData: DBVoice[] = await fetchVoicesFromDB();
      const mappedVoices: Voice[] = voicesData.map((dbVoice) => ({
        description: <span>{dbVoice.name}</span>,
        voice_name: dbVoice.name,
        voice_id: dbVoice.id,
        gender: dbVoice.gender,
        sampleUrl: '',
      }));
      setVoices(mappedVoices);
    } catch (err) {
      console.error('Error fetching voices: ', err);
      setError('Failed to fetch voices data');
    }
  }, []);

  const fetchAgentFiles = useCallback(async () => {
    try {
      const fileIds = await fetchFileIds(assistantId);
      const fileDetailsPromises = fileIds.map((fileId: string) =>
        fetchFileDetails(fileId)
      );
      const fileDetails = await Promise.all(fileDetailsPromises);
      setFiles(fileDetails);
    } catch (err) {
      console.error('Error fetching files: ', err);
      setError('Failed to fetch files');
    }
  }, [assistantId]);

  useEffect(() => {
    const fetchAllData = async () => {
      await fetchAssistantData();
      await fetchMetricsData();
      await fetchAssistantPhoneNumber();
      await fetchVoicesData();
      await fetchAgentFiles();
    };

    fetchAllData();
  }, [
    fetchAssistantData,
    fetchMetricsData,
    fetchAssistantPhoneNumber,
    fetchVoicesData,
    fetchAgentFiles,
  ]);

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleVoiceSelect = (voiceId: string) => {
    setFormData((prevData) => (prevData ? { ...prevData, voice: voiceId } : null));
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      try {
        const data = await uploadFile(file, assistantId);
        setFormData((prev) =>
          prev ? { ...prev, knowledgeBase: data.name } : null
        );
        await fetchAgentFiles();
      } catch (err) {
        console.error('Error uploading file: ', err);
        setError('Failed to upload file');
      }
    }
  };

  const handleDeleteFile = async (fileId: string) => {
    try {
      await deleteFile(fileId);
      await fetchAgentFiles();
    } catch (err) {
      console.error('Error deleting file: ', err);
      setError('Failed to delete file');
    }
  };

  const handleDeleteAgent = async () => {
    try {
      await deleteAssistant(assistantId);
      window.dispatchEvent(
        new CustomEvent('assistantDeleted', { detail: assistantId })
      );
      navigate('/');
    } catch (error) {
      console.error('Error deleting assistant:', error);
      setError(
        'Failed to delete assistant: ' +
          (error instanceof Error ? error.message : String(error))
      );
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData) return;
    setIsUpdating(true);
    try {
      const updatedData = {
        name: formData.name,
        generalKnowledge: formData.generalKnowledge,
        skills: formData.skills,
        personality: formData.personality,
        highlight: formData.highlights,
        voiceId: formData.voice,
        gender: formData.gender,
        phoneNumbers: phoneNumbers,
        profileImageUrl: formData.profileImageUrl || undefined,
      };
      const updatedAgentData = await updateAgentData(assistantId, updatedData);
      setFormData((prevData) =>
        prevData ? { ...prevData, ...updatedAgentData } : null
      );
      setShowSuccessMessage(true);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    } catch (err) {
      console.error('Error updating data: ', err);
      setError(
        'Failed to update assistant data: ' +
          (err instanceof Error ? err.message : String(err))
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: { main: isDarkMode ? '#90caf9' : '#3f51b5' },
      secondary: { main: isDarkMode ? '#f48fb1' : '#f50057' },
      background: { default: isDarkMode ? '#303030' : '#f5f5f5' },
    },
    typography: { fontFamily: 'Roboto, Arial, sans-serif' },
    shape: { borderRadius: 8 },
  });

  if (error) {
    return (
      <StyledContainer>
        <Alert severity="error" variant="filled">
          {error}
        </Alert>
      </StyledContainer>
    );
  }

  if (loading || !formData) {
    return (
      <StyledContainer>
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress size={60} />
        </Box>
      </StyledContainer>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledContainer>
        {showConfetti && <ReactConfetti recycle={false} numberOfPieces={200} />}
        <StyledTabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab label="Overview" />
          <Tab label="Voice Selection" />
          <Tab label="Knowledge Base" />
          <Tab label="Recent Activity" />
        </StyledTabs>

        <Box mt={3}>
          {selectedTab === 0 && (
            <>
              <AgentBasicInfo
                formData={formData}
                handleTextareaChange={handleTextareaChange}
                handleSubmit={handleSubmit}
                isUpdating={isUpdating}
                phoneNumbers={phoneNumbers}
                handleImageUpload={handleImageUpload}
                handleDeleteAgent={handleDeleteAgent}
                assistantId={assistantId}
                setFormData={setFormData}
              />
              {metrics && <ChartsSection metrics={metrics} />}
            </>
          )}

          {selectedTab === 1 && (
            <StyledPaper>
              <AgentProfileVoices
                voices={voices}
                selectedVoice={formData.voice}
                onVoiceSelect={handleVoiceSelect}
              />
            </StyledPaper>
          )}

          {selectedTab === 2 && (
            <AgentKnowledgeBase
              files={files}
              handleFileUpload={handleFileUpload}
              handleDeleteFile={handleDeleteFile}
            />
          )}

          {selectedTab === 3 && (
            <StyledPaper>
              <RecentActivityFeed assistantId={assistantId} />
              {metrics && <ChartsSection metrics={metrics} />}
            </StyledPaper>
          )}
        </Box>

        <Snackbar
          open={showSuccessMessage}
          autoHideDuration={6000}
          onClose={() => setShowSuccessMessage(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setShowSuccessMessage(false)}
            severity="success"
            sx={{
              width: '100%',
              backgroundColor: theme.palette.success.main,
              color: theme.palette.common.white,
            }}
          >
            Agent updated successfully!
          </Alert>
        </Snackbar>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default AgentProfile;
