// src/components/agent_builder_basic/BasicInfoBasic.tsx

import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Grid,
  Paper,
  CircularProgress,
} from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { AgentData, UserPlanDetails } from '@/types/AgentTypes';
import UserPlanDisplay from './UserPlanDisplay';
import { fetchUserPlanDetails } from '@/api/user';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: theme.palette.background.default,
  boxShadow: '0 8px 32px rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(4px)',
  borderRadius: '10px',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  marginBottom: theme.spacing(3),
}));

interface BasicInfoBasicProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

const BasicInfoBasic: React.FC<BasicInfoBasicProps> = ({ agentData, onChange }) => {
  const [userPlan, setUserPlan] = useState<UserPlanDetails | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlanDetails = async () => {
      try {
        const planDetails = await fetchUserPlanDetails();
        setUserPlan(planDetails);
      } catch (error) {
        console.error('Error fetching user plan details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlanDetails();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange({ [name]: value });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <StyledPaper>
        <Typography
          variant="h5"
          gutterBottom
          component={motion.h5}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Agent Basic Information
        </Typography>

        {userPlan && (
          <UserPlanDisplay
            planName={userPlan.planName}
            planFeatures={userPlan.features}
            expiryDate={userPlan.expiryDate}
          />
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={agentData.name || ''}
              onChange={handleChange}
              variant="outlined"
              required
              error={!agentData.name}
              helperText={!agentData.name ? 'Name is required' : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Role"
              name="role"
              value={agentData.role || ''}
              onChange={handleChange}
              variant="outlined"
              required
              error={!agentData.role}
              helperText={!agentData.role ? 'Role is required' : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="General Knowledge"
              name="generalKnowledge"
              value={agentData.generalKnowledge || ''}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={4}
              required
              error={!agentData.generalKnowledge}
              helperText={
                !agentData.generalKnowledge ? 'General Knowledge is required' : ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Highlights"
              name="highlight"
              value={agentData.highlight || ''}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={4}
              required
              error={!agentData.highlight}
              helperText={!agentData.highlight ? 'Highlight is required' : ''}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </motion.div>
  );
};

export default BasicInfoBasic;