// src/api/billing.ts

import api from './api';
import { handleError } from '../utils/errors';
import { 
  UserMetrics, 
  AgentMetrics 
} from '@/types/AgentTypes'; 
import { 
  CryptoCurrency, 
  BillingMetrics, 
  BillingInfo, 
  BillingData, 
  Invoice, 
  PaymentIntent, 
  CreateCryptoChargeRequest,
  CreateCryptoChargeResponse, // Import the CreateCryptoChargeResponse type
} from '@/types/BillingTypes'; 

// Fetch billing metrics
export const fetchBillingMetrics = async (rangeStart: string, rangeEnd: string): Promise<BillingMetrics> => {
  try {
    const response = await api.get<BillingMetrics>(`/api/billing/metrics`, {
      params: { rangeStart, rangeEnd },
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch billing metrics');
    throw error; // Ensure the function always returns a value or throws
  }
};

// Get billing info for an account
export const getBillingInfo = async (accountId: string): Promise<BillingInfo> => {
  try {
    const response = await api.get<BillingInfo>(`/api/billing/accounts/${accountId}/billing`);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to get billing info');
    throw error;
  }
};

// Update billing info for an account
export const updateBillingInfo = async (accountId: string, billingData: BillingData): Promise<BillingInfo> => {
  try {
    const response = await api.put<BillingInfo>(`/api/billing/accounts/${accountId}/billing`, billingData);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update billing info');
    throw error;
  }
};

// Get invoices for an account
export const getInvoices = async (accountId: string): Promise<Invoice[]> => {
  try {
    const response = await api.get<Invoice[]>(`/api/billing/accounts/${accountId}/invoices`);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to get invoices');
    return [];
  }
};

// Create a payment intent
export const createPaymentIntent = async (
  amount: number,
  currency: string,
  paymentMethodTypes: string[] = ['card', 'us_bank_account']
): Promise<PaymentIntent> => {
  try {
    const response = await api.post<PaymentIntent>('/api/billing/create-payment-intent', {
      amount,
      currency,
      payment_method_types: paymentMethodTypes,
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create payment intent');
    throw error;
  }
};

// Fetch supported cryptocurrencies
export const fetchCryptoCurrencies = async (): Promise<CryptoCurrency[]> => {
  try {
    const response = await api.get<CryptoCurrency[]>('/api/billing/crypto-currencies');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch cryptocurrencies');
    return [];
  }
};

// Create a crypto charge
export const createCryptoCharge = async (
  amount: number,
  currency: string,
  selectedCrypto: string
): Promise<CreateCryptoChargeResponse> => { // Update the return type
  try {
    const requestData: CreateCryptoChargeRequest = {
      amount,
      currency,
      selectedCrypto,
    };
    const response = await api.post<CreateCryptoChargeResponse>('/api/billing/create-crypto-charge', requestData);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create crypto charge');
    throw error;
  }
};

// Update user metrics
export const updateUserMetrics = async (): Promise<void> => {
  try {
    await api.post('/api/billing/update-metrics');
  } catch (error) {
    handleError(error, 'Error updating user metrics');
    throw error;
  }
};

// Fetch user metrics
export const fetchUserMetrics = async (assistantId: string): Promise<UserMetrics[]> => {
  try {
    const response = await api.get<UserMetrics[]>(`/api/metrics/${assistantId}`);
    return response.data.map(metric => ({
      ...metric,
      total_duration: Number(metric.totalDuration),
    }));
  } catch (error) {
    handleError(error, 'Failed to fetch user metrics');
    return [];
  }
};

// Get agent metrics
export const getAgentMetrics = async (assistantId: string): Promise<AgentMetrics> => {
  try {
    const response = await api.get<AgentMetrics>(`/api/assistants/metrics/${assistantId}`);
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching agent metrics');
    throw error;
  }
};

// Fetch and store metrics
export const fetchAndStoreMetrics = async (
  assistantId: string
): Promise<{ message: string }> => {
  try {
    const response = await api.post<{ message: string }>(
      `/api/assistants/metrics/fetch`,
      {}, // Changed from null to {}
      {
        params: { assistantId },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching and storing metrics');
    throw error;
  }
};
