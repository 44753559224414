import React from 'react';
import { Card, CardContent, CardActions, Button, Typography, Grid, Avatar } from '@mui/material';
import { motion } from 'framer-motion';
import { AgentTemplate } from '@/types/AgentTypes';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CodeIcon from '@mui/icons-material/Code';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import customerServiceImage from '../../assets/images/customer_service_agent.jpg';
import technicalSupportImage from '../../assets/images/technical_support_agent.jpg';
import foodServiceImage from '../../assets/images/food_service_agent.jpg';
import realEstateImage from '../../assets/images/real_estate_agent.jpg';
import financialServicesImage from '../../assets/images/financial_services_agent.jpg';
import serviceAgentImage from '../../assets/images/service_agent.jpg';
import automotiveSalesImage from '../../assets/images/automotive_sales_agent.jpg';
import bdcAgentImage from '../../assets/images/bdc_agent.jpg';
import defaultAvatar from 'src/assets/images/divergent-agents-logo.png';

interface TemplateSelectionProps {
  onSelect: (template: AgentTemplate) => void;
}

// Map roles to icons (used when image is not available)
const iconMap: { [key: string]: React.ReactElement } = {
  'Customer Service': <SupportAgentIcon />,
  Sales: <ShoppingCartIcon />,
  'Technical Support': <CodeIcon />,
  'Food Service': <RestaurantIcon />,
  'Real Estate': <HomeIcon />,
  'Financial Services': <AttachMoneyIcon />,
  'Service Agent': <DirectionsCarIcon />,
  'Sales Agent': <DirectionsCarIcon />,
  'BDC Agent': <DirectionsCarIcon />,
};

// Define templates with all agent templates included
const templates: AgentTemplate[] = [
  {
    name: 'Customer Service Agent',
    role: 'Customer Service',
    skill: [
      { id: 'skill1', name: 'Issue Resolution', category: 'Customer Service' },
      { id: 'skill2', name: 'Communication', category: 'Soft Skills' },
    ],
    generalKnowledge:
      'Strong understanding of customer service processes, company policies, and issue resolution techniques',
    personality: {
      Friendliness: 90,
      Professionalism: 85,
      Enthusiasm: 80,
      Patience: 90,
      Assertiveness: 70,
      Adaptability: 85,
      Empathy: 95,
    },
    highlight:
      'Excels at de-escalating situations and ensuring customer satisfaction',
    profileImageUrl: customerServiceImage,
  },
  {
    name: 'Technical Support Agent',
    role: 'Technical Support',
    skill: [
      { id: 'skill3', name: 'Troubleshooting', category: 'Technical' },
      { id: 'skill4', name: 'Step-by-step Guidance', category: 'Communication' },
    ],
    generalKnowledge:
      'Deep knowledge of technical systems, software troubleshooting, and hardware diagnostics',
    personality: {
      Friendliness: 80,
      Professionalism: 75,
      Enthusiasm: 90,
      Patience: 65,
      Assertiveness: 80,
      Adaptability: 85,
      Empathy: 80,
    },
    highlight:
      'Specializes in guiding non-technical users through complex troubleshooting steps',
    profileImageUrl: technicalSupportImage,
  },
  {
    name: 'Food Service Agent',
    role: 'Food Service',
    skill: [
      { id: 'skill5', name: 'Order Management', category: 'Customer Service' },
      { id: 'skill6', name: 'Complaint Resolution', category: 'Customer Service' },
    ],
    generalKnowledge:
      'Knowledgeable in menu options, food safety regulations, and restaurant operations',
    personality: {
      Friendliness: 85,
      Professionalism: 80,
      Enthusiasm: 85,
      Patience: 75,
      Assertiveness: 90,
      Adaptability: 95,
      Empathy: 85,
    },
    highlight:
      'Known for delivering excellent customer service while managing high-volume orders',
    profileImageUrl: foodServiceImage,
  },
  {
    name: 'Real Estate Agent',
    role: 'Real Estate',
    skill: [
      { id: 'skill7', name: 'Property Listing', category: 'Real Estate' },
      { id: 'skill8', name: 'Client Consultation', category: 'Customer Service' },
    ],
    generalKnowledge:
      'Comprehensive understanding of property valuation, real estate laws, and market analysis',
    personality: {
      Friendliness: 85,
      Professionalism: 90,
      Enthusiasm: 85,
      Patience: 85,
      Assertiveness: 90,
      Adaptability: 85,
      Empathy: 90,
    },
    highlight:
      'Specializes in matching clients with their ideal properties while navigating complex negotiations',
    profileImageUrl: realEstateImage,
  },
  {
    name: 'Financial Services Agent',
    role: 'Financial Services',
    skill: [
      { id: 'skill9', name: 'Financial Planning', category: 'Finance' },
      { id: 'skill10', name: 'Investment Strategy', category: 'Finance' },
    ],
    generalKnowledge:
      'In-depth knowledge of financial markets, tax laws, and investment options',
    personality: {
      Friendliness: 85,
      Professionalism: 75,
      Enthusiasm: 85,
      Patience: 80,
      Assertiveness: 95,
      Adaptability: 90,
      Empathy: 80,
    },
    highlight:
      "Specializes in providing tailored financial advice that aligns with clients' long-term goals",
    profileImageUrl: financialServicesImage,
  },
  {
    name: 'Service Agent',
    role: 'Service Agent',
    skill: [
      { id: 'skill11', name: 'Automotive Service', category: 'Technical' },
      { id: 'skill12', name: 'Maintenance', category: 'Technical' },
    ],
    generalKnowledge:
      'Comprehensive understanding of vehicle diagnostics and repair procedures',
    personality: {
      Friendliness: 85,
      Professionalism: 80,
      Enthusiasm: 90,
      Patience: 75,
      Assertiveness: 85,
      Adaptability: 85,
      Empathy: 80,
    },
    highlight: 'Specializes in explaining complex technical issues in simple terms',
    profileImageUrl: serviceAgentImage,
  },
  {
    name: 'Automotive Sales Agent',
    role: 'Sales Agent',
    skill: [
      { id: 'skill13', name: 'Sales Techniques', category: 'Sales' },
      { id: 'skill14', name: 'Product Knowledge', category: 'Automotive' },
    ],
    generalKnowledge:
      'In-depth understanding of various car models, features, and market trends',
    personality: {
      Friendliness: 90,
      Professionalism: 85,
      Enthusiasm: 85,
      Patience: 80,
      Assertiveness: 90,
      Adaptability: 85,
      Empathy: 85,
    },
    highlight: 'Excels at matching customers with their ideal vehicles',
    profileImageUrl: automotiveSalesImage,
  },
  {
    name: 'BDC Agent',
    role: 'BDC Agent',
    skill: [
      { id: 'skill15', name: 'Customer Relationship Management', category: 'Customer Service' },
      { id: 'skill16', name: 'Lead Qualification', category: 'Sales' },
    ],
    generalKnowledge:
      'Broad understanding of dealership operations and customer service best practices',
    personality: {
      Friendliness: 85,
      Professionalism: 90,
      Enthusiasm: 90,
      Patience: 80,
      Assertiveness: 90,
      Adaptability: 85,
      Empathy: 85,
    },
    highlight: 'Specializes in efficiently managing and prioritizing customer inquiries',
    profileImageUrl: bdcAgentImage,
  },
];

const TemplateSelection: React.FC<TemplateSelectionProps> = ({ onSelect }) => {
  return (
    <Grid container spacing={3}>
      {templates.map((template, index) => (
        <Grid item xs={12} sm={6} md={4} key={template.name}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1 }}>
                {template.profileImageUrl ? (
                  <Avatar
                    src={template.profileImageUrl}
                    sx={{ width: 56, height: 56, mb: 2 }}
                  />
                ) : iconMap[template.role] ? (
                  <Avatar sx={{ width: 56, height: 56, mb: 2 }}>
                    {iconMap[template.role]}
                  </Avatar>
                ) : (
                  <Avatar
                    src={defaultAvatar}
                    sx={{ width: 56, height: 56, mb: 2 }}
                  />
                )}
                <Typography variant="h5" component="div" gutterBottom>
                  {template.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {template.highlight}
                </Typography>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  Key Skills:
                </Typography>
                <ul>
                  {template.skill.map((skillItem, idx) => (
                    <li key={idx}>
                      <Typography variant="body2">{skillItem.name}</Typography>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => onSelect(template)}
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 'auto',
                    transition: 'all 0.3s',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 3,
                    },
                  }}
                >
                  Select Template
                </Button>
              </CardActions>
            </Card>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
};

export default TemplateSelection;