import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  CssBaseline,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import ClientSelection from './ClientSelection';
import AgentBasicInfo from './BasicInfo';
import VoiceSelection from './VoiceSelection';
import SkillBuilder from './SkillBuilder';
import PersonalityBuilder from './PersonalityBuilder';
import PhoneNumberAssignment from './PhoneNumberAssignment';
import WebhookPreview from './WebhookPreview';
import { useTheme as useCustomTheme } from '@/components/ThemeContext';
import { AgentData, ClientData } from '@/types/AgentTypes';
import { createAgent, updateAgentData } from '@/api/assistant'; // Updated import
import { searchClients } from '@/api/client';

const AdvancedAgentBuilder: React.FC = () => {
  const { isDarkMode } = useCustomTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [clientData, setClientData] = useState<ClientData | null>(null);
  const [agentData, setAgentData] = useState<AgentData>({
    name: '',
    role: '',
    status: 'Inactive',
    dateUpdated: new Date().toISOString(),
    skills: [],
    generalKnowledge: '',
    personality: {},
    voiceId: '',
    highlight: '',
    assistantId: undefined,
    transcriberProvider: '',
    transcriberModel: '',
    transcriberLanguage: '',
    provider: '',
    model: '',
    messages: [],
    voiceProvider: '',
    voiceIdSecondary: '', // Added property
    phoneNumbers: [],
    gender: '',
    dealershipName: '',
    dealershipWebsiteUrl: '',
    dealerInventorySearchUrlParameters: '',
    enableInventoryLookupTool: false,
    dealershipId: null,
    dealershipAddress: '',
    salesTarget: null,
    preferredVehicleBrands: [],
    accountId: null,
    assistantDemo: null,
    emailSummaryEnabled: false,
    emailSummaryAddress: null,
    firstName: '',
    lastName: '',
    dashboardAccess: false,
    isAdmin: false,
    address: '',
    customerNote: '',
    dmsApis: [],
    email: '',
    country: '',
    state: '',
    zip: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState<string | null>(null);

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const steps = [
    'Select Client',
    'Agent Basic Information',
    'Voice Selection',
    'Skill Builder',
    'Personality Builder',
    'Phone Number Assignment',
    'Review & Submit',
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClientSelect = (client: ClientData) => {
    setClientData(client);
    handleNext();
  };

  const handleAgentDataChange = (newData: Partial<AgentData>) => {
    setAgentData((prevData) => ({ ...prevData, ...newData }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setSubmitResult(null);
    try {
      const fullAgentData = {
        ...agentData,
        clientId: clientData?.id,
        dealershipName: clientData?.dealershipName,
        dealershipWebsiteUrl: clientData?.dealershipWebsiteUrl,
        dealershipAddress: clientData?.address,
        dealershipId: clientData?.dealershipId,
      };

      let result;
      if (agentData.assistantId) {
        result = await updateAgentData(agentData.assistantId, fullAgentData); // Updated function name
        setSubmitResult(`Agent updated successfully: ${result.assistantId}`);
      } else {
        result = await createAgent(fullAgentData);
        setSubmitResult(`Agent created successfully: ${result.assistantId}`);
      }
      // Optionally reset form or navigate to a success page
    } catch (error) {
      setSubmitResult(
        `Error ${agentData.assistantId ? 'updating' : 'creating'} agent: ${
          (error as Error).message
        }`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ClientSelection
            onSelect={handleClientSelect}
            onSearch={searchClients}
          />
        );
      case 1:
        return (
          <AgentBasicInfo
            agentData={agentData}
            onChange={handleAgentDataChange}
          />
        );
      case 2:
        return (
          <VoiceSelection
            agentData={agentData}
            onChange={handleAgentDataChange}
          />
        );
      case 3:
        return (
          <SkillBuilder
            agentData={agentData}
            onChange={handleAgentDataChange}
          />
        );
      case 4:
        return (
          <PersonalityBuilder
            agentData={agentData}
            onChange={handleAgentDataChange}
          />
        );
      case 5:
        return (
          <PhoneNumberAssignment
            agentData={agentData}
            onChange={handleAgentDataChange}
          />
        );
      case 6:
        return (
          <WebhookPreview
            agentData={{
              ...agentData,
              ...clientData,
              id: clientData?.id ?? undefined,
            }}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ width: '100%', p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Advanced Agent Builder
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <AnimatePresence mode="wait">
          <motion.div
            key={activeStep}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            <Box sx={{ mt: 4, mb: 4 }}>{getStepContent(activeStep)}</Box>
          </motion.div>
        </AnimatePresence>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {activeStep !== 0 && (
            <Button onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
          )}
          <Button
            variant="contained"
            onClick={
              activeStep === steps.length - 1 ? handleSubmit : handleNext
            }
            disabled={isSubmitting}
          >
            {activeStep === steps.length - 1
              ? isSubmitting
                ? 'Submitting...'
                : 'Submit'
              : 'Next'}
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={!!submitResult}
        autoHideDuration={6000}
        onClose={() => setSubmitResult(null)}
      >
        <Alert
          onClose={() => setSubmitResult(null)}
          severity={submitResult?.includes('Error') ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {submitResult}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default AdvancedAgentBuilder;