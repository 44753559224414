// src/api/voice.ts

import api from './api';
import { handleError } from '../utils/errors';
import config from '../config';
import { ElevenLabsVoice, DBVoice } from '@/types/VoiceTypes'; // Adjust the import path as necessary

/**
 * Fetch voices from Eleven Labs API.
 */
export const fetchVoices = async (): Promise<ElevenLabsVoice[]> => {
  try {
    const response = await api.get<ElevenLabsVoice[]>(config.ELEVENLABS_API_URL, {
      headers: {
        'Content-Type': 'application/json',
        'xi-api-key': config.ELEVENLABS_API_KEY,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch voices from Eleven Labs API');
    throw error; // Ensure the function either returns the expected type or throws
  }
};

/**
 * Fetch voices from the database.
 */
export const fetchVoicesFromDB = async (): Promise<DBVoice[]> => {
  try {
    const response = await api.get<DBVoice[]>('/api/voices');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch voices from the database');
    throw error;
  }
};
