// VoiceSelectionBasic Component

import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import { motion } from 'framer-motion';
import WaveSurfer from 'wavesurfer.js';
import { AgentData } from '@/types/AgentTypes';
import { DBVoice } from '@/types/VoiceTypes';
import { fetchVoicesFromDB } from '@/api/voice';

/**
 * Represents a voice tailored for the VoiceSelectionBasic component.
 */
interface Voice {
  id: string;
  name: string;
  gender: string;
  type?: string;
  description: string;
  sampleUrl?: string;
}

interface VoiceSelectionProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

const VoiceSelectionBasic: React.FC<VoiceSelectionProps> = ({
  agentData,
  onChange,
}) => {
  const [voices, setVoices] = useState<Voice[]>([]);
  const [playingVoice, setPlayingVoice] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const waveformRefs = useRef<{ [key: string]: WaveSurfer | null }>({});

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        setLoading(true);
        const voicesData: DBVoice[] = await fetchVoicesFromDB();

        // Map DBVoice[] to Voice[]
        const mappedVoices: Voice[] = voicesData.map((dbVoice: DBVoice) => ({
          id: dbVoice.id,
          name: dbVoice.name,
          gender: dbVoice.gender || 'Unknown',
          // 'type' can be set based on your application's logic or fetched from another source
          type: 'Standard', // Example default value
          description: dbVoice.description || 'No description available', // Default value; replace as needed
          sampleUrl: `/assets/voice-samples/${dbVoice.name}.mp3`,
        }));

        setVoices(mappedVoices);
        setError(null);
      } catch (error) {
        console.error(error);
        setError('Failed to load voices. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchVoices();
  }, []);

  useEffect(() => {
    voices.forEach((voice) => {
      if (!waveformRefs.current[voice.id]) {
        const wavesurfer = WaveSurfer.create({
          container: `#waveform-${voice.id}`,
          waveColor: '#3f51b5',
          progressColor: '#1a237e',
          cursorColor: '#303f9f',
          height: 40,
          fillParent: true,
          barWidth: 2,
          barRadius: 3,
          cursorWidth: 1,
          barGap: 3,
        });
        wavesurfer.load(voice.sampleUrl || '');
        waveformRefs.current[voice.id] = wavesurfer;
      }
    });

    return () => {
      Object.values(waveformRefs.current).forEach((wavesurfer) => {
        if (wavesurfer) {
          wavesurfer.destroy();
        }
      });
      waveformRefs.current = {};
    };
  }, [voices]);

  const handleVoiceSelect = (voiceId: string) => {
    onChange({ voiceId });
  };

  const handlePlaySample = (voiceId: string) => {
    if (playingVoice) {
      waveformRefs.current[playingVoice]?.stop();
    }
    if (playingVoice !== voiceId) {
      waveformRefs.current[voiceId]?.play();
      setPlayingVoice(voiceId);
    } else {
      setPlayingVoice(null);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Voice Selection
      </Typography>
      <Grid container spacing={3}>
        {voices.map((voice) => (
          <Grid item xs={12} sm={6} md={4} key={voice.id}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Card
                variant={
                  agentData.voiceId === voice.id
                    ? 'outlined'
                    : 'elevation'
                }
                sx={{
                  borderColor:
                    agentData.voiceId === voice.id
                      ? 'primary.main'
                      : 'transparent',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h6">{voice.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {voice.type ? `${voice.type} - ` : ''}
                    {voice.description}
                  </Typography>
                  <Box id={`waveform-${voice.id}`} sx={{ mt: 2 }} />
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => handlePlaySample(voice.id)}
                  >
                    {playingVoice === voice.id ? 'Pause' : 'Play Sample'}
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleVoiceSelect(voice.id)}
                  >
                    Select Voice
                  </Button>
                </CardActions>
                {agentData.voiceId === voice.id && (
                  <Box
                    sx={{
                      p: 1,
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                    }}
                  >
                    <Typography variant="body2" align="center">
                      Selected
                    </Typography>
                  </Box>
                )}
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </motion.div>
  );
};

export default VoiceSelectionBasic;