// src/components/agentprofile/AgentProfileCharts.tsx

import React from 'react';
import { Grid, Paper, Skeleton, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import BillingChart from '@/components/charts/BillingChart';
import WeeklyCallChart from '@/components/charts/WeeklyCallChart';
import MonthlyMinutesChart from '@/components/charts/MonthlyMinutesChart';
import { UserMetrics } from '@/types/AgentTypes';
import { styled } from '@mui/material/styles';

const StyledCard = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  backgroundColor: theme.palette.background.paper,
  transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
    transform: 'translateY(-5px)',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.2rem',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.main,
}));

interface MetricsSummaryProps {
  metrics: UserMetrics[];
}

export const MetricsSummary: React.FC<MetricsSummaryProps> = ({ metrics }) => {
  const totalCalls = metrics.reduce((sum, metric) => sum + Number(metric.callCount) || 0, 0);
  const totalCost = metrics.reduce((sum, metric) => sum + Number(metric.totalCost) || 0, 0);
  const totalMinutes = metrics.reduce((sum, metric) => sum + (Number(metric.totalDuration) || 0) / 60, 0);
  const averageCallCost = totalCalls > 0 ? totalCost / totalCalls : 0;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#252525', color: 'white' }}>
          <Typography variant="h6">Total Minutes</Typography>
          <Typography variant="h4">{totalMinutes.toFixed(2)}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#252525', color: 'white' }}>
          <Typography variant="h6">Total Call Count</Typography>
          <Typography variant="h4">{totalCalls}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#252525', color: 'white' }}>
          <Typography variant="h6">Average Call Cost</Typography>
          <Typography variant="h4">${averageCallCost.toFixed(2)}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#252525', color: 'white' }}>
          <Typography variant="h6">Total Cost</Typography>
          <Typography variant="h4">${totalCost.toFixed(2)}</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

interface ChartsSectionProps {
  metrics: UserMetrics[];
}

export const ChartsSection: React.FC<ChartsSectionProps> = ({ metrics }) => {
  const processChartData = (metrics: UserMetrics[]) => {
    const billDailyBreakdown = metrics.reduce((acc, metric) => {
      const date = new Date(metric.date);
      if (isNaN(date.getTime())) {
        console.warn(`Invalid date encountered: ${metric.date}`);
        return acc;
      }
      const dateString = date.toISOString().split('T')[0];
      acc[dateString] = (acc[dateString] || 0) + (Number(metric.totalCost) || 0);
      return acc;
    }, {} as Record<string, number>);

    const callCountDailyBreakdown = metrics.reduce((acc, metric) => {
      const date = new Date(metric.date);
      if (isNaN(date.getTime())) {
        console.warn(`Invalid date encountered: ${metric.date}`);
        return acc;
      }
      const dateString = date.toISOString().split('T')[0];
      acc[dateString] = (acc[dateString] || 0) + (Number(metric.callCount) || 0);
      return acc;
    }, {} as Record<string, number>);

    const callMinutesDailyBreakdown = metrics.reduce((acc, metric) => {
      const date = new Date(metric.date);
      if (isNaN(date.getTime())) {
        console.warn(`Invalid date encountered: ${metric.date}`);
        return acc;
      }
      const dateString = date.toISOString().split('T')[0];
      acc[dateString] =
        (acc[dateString] || 0) + ((Number(metric.totalDuration) || 0) / 60);
      return acc;
    }, {} as Record<string, number>);

    return { billDailyBreakdown, callCountDailyBreakdown, callMinutesDailyBreakdown };
  };

  const chartData = processChartData(metrics);

  return (
    <Grid container spacing={3} sx={{ mt: 3 }}>
      <Grid item xs={12} md={4}>
        <StyledCard
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <SectionTitle>Billing Overview</SectionTitle>
          {metrics.length > 0 ? (
            <BillingChart
              billingData={Object.entries(chartData.billDailyBreakdown).map(([date, bill]) => ({
                date,
                bill,
              }))}
            />
          ) : (
            <Skeleton variant="rectangular" width="100%" height={200} />
          )}
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <StyledCard
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <SectionTitle>Weekly Call Statistics</SectionTitle>
          {metrics.length > 0 ? (
            <WeeklyCallChart
              weeklyData={Object.entries(chartData.callCountDailyBreakdown).map(
                ([date, calls]) => ({
                  day: date,
                  calls: calls,
                  minutes: chartData.callMinutesDailyBreakdown[date] || 0,
                })
              )}
            />
          ) : (
            <Skeleton variant="rectangular" width="100%" height={200} />
          )}
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <StyledCard
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <SectionTitle>Monthly Minutes Usage</SectionTitle>
          {metrics.length > 0 ? (
            <MonthlyMinutesChart
              monthlyData={Object.entries(chartData.callMinutesDailyBreakdown).map(
                ([date, minutes]) => ({
                  day: date,
                  minutes: minutes,
                })
              )}
            />
          ) : (
            <Skeleton variant="rectangular" width="100%" height={200} />
          )}
        </StyledCard>
      </Grid>
    </Grid>
  );
};
