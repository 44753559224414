// src/api/assistant.ts

import api from './api';
import { handleError, makeVapiCall } from '../utils';
import { 
  AgentData, 
  AgentTemplate, 
  NewAgentData, 
  Skill, 
  BuyPhoneNumberRequest, 
  PhoneNumber,
  ToolConfig,
  AssignToolResponse,
  UserAgentResponse,
  XTimeToolResponse,
  DemoCallResponse,
} from '@/types/AgentTypes';
import { BackendAgentTemplate } from '@/types/BackendTypes';
import { adaptAgentTemplate, adaptAgentData } from '../utils/agentDataAdapter';
import config from '../config';
import { ApiError } from '../utils/errors';


// Fetch agent data
export const fetchAgentData = async (assistantId: string): Promise<AgentData> => {
  try {
    const response = await api.get<BackendAgentTemplate>(`/api/assistants/${assistantId}`);
    return adaptAgentData(response.data);
  } catch (error) {
    handleError(error, 'Error fetching agent data');
    throw error;
  }
};


// Create agent
export const createAgent = async (agentData: Partial<AgentData>): Promise<AgentData> => {
  try {
    const response = await api.post<BackendAgentTemplate>('/api/assistants', agentData);
    return adaptAgentData(response.data);
  } catch (error) {
    handleError(error, 'Error creating agent');
    throw error;
  }
};

// Update agent
export const updateAgentData = async (
  agentId: string,
  updatedData: Partial<AgentData>
): Promise<AgentData> => {
  console.log(`Updating agent ${agentId} with data:`, updatedData);

  if (!agentId) {
    throw new ApiError('Agent ID is required');
  }

  if (!updatedData.name) {
    throw new ApiError('Name is required and cannot be null');
  }

  const dataToSend = {
    name: updatedData.name,
    generalKnowledge: updatedData.generalKnowledge,
    skills: updatedData.skills || [],
    personality: updatedData.personality || {},
    highlights: updatedData.highlight,
    voice: updatedData.voiceId,
    gender: updatedData.gender,
  };

  console.log('Data to send:', dataToSend);

  try {
    const response = await api.put<BackendAgentTemplate>(`/api/assistants/${agentId}`, dataToSend);
    console.log(`Successfully updated agent ${agentId}`, response.data);

    return adaptAgentData(response.data);
  } catch (error) {
    console.error('Failed to update agent data:', error);
    throw error;
  }
};

// Delete assistant
export const deleteAssistant = async (assistantId: string): Promise<void> => {
  try {
    await api.delete(`/api/assistants/${assistantId}`);
  } catch (error) {
    handleError(error, 'Error deleting assistant');
    throw error;
  }
};

// Update assistant profile image
export const updateAssistantProfileImage = async (
  assistantId: string,
  file: File
): Promise<{
  profile_image_url: string | null;
  message: string;
  filePath: string;
}> => {
  const formData = new FormData();
  formData.append('profileImage', file);

  try {
    const response = await api.post<{
      profile_image_url: string;
      message: string;
      filePath: string;
    }>(
      `/api/assistants/${assistantId}/profile-image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return {
      profile_image_url: response.data.profile_image_url || null, // Ensure null instead of undefined
      message: response.data.message,
      filePath: response.data.filePath,
    };
  } catch (error) {
    handleError(error, 'Error updating assistant profile image');
  }
};

// Fetch assistant name
export const fetchAssistantName = async (assistantId: string): Promise<string> => {
  try {
    const response = await api.get<{ name: string }>(`/api/assistants/${assistantId}/name`);
    return response.data.name;
  } catch (error) {
    handleError(error, 'Error fetching assistant name');
  }
};

// Fetch agent templates
export const fetchAgentTemplates = async (): Promise<AgentTemplate[]> => {
  try {
    const response = await api.get<BackendAgentTemplate[]>('/api/agent-templates');
    return response.data.map(adaptAgentTemplate);
  } catch (error) {
    handleError(error, 'Error fetching agent templates');
    throw error;
  }
};

// Send agent webhook
export const sendAgentWebhook = async (agentData: Partial<AgentData> & { assistantId: string }): Promise<string> => {
  try {
    const formattedData = {
      assistant_id: agentData.assistantId,
      name: agentData.name || '',
      general_knowledge: agentData.generalKnowledge || '',
      skill: agentData.skills || [],
      personality: agentData.personality || {},
      highlight: agentData.highlight || '',
      voiceid: agentData.voiceId || '',
      gender: agentData.gender || '',
    };

    const response = await api.post<{ message: string }>(config.MAKE_ENDPOINT, formattedData);
    return response.data.message;
  } catch (error) {
    handleError(error, 'Failed to send agent webhook');
    throw error;
  }
};

// Send advanced agent webhook
export const sendAdvancedAgentWebhook = async (agentData: AgentData): Promise<string> => {
  try {
    const response = await api.post<{ message: string }>(config.ADVANCED_AGENT_WEBHOOK_URL, agentData);
    return response.data.message;
  } catch (error) {
    handleError(error, 'Failed to send advanced agent webhook');
  }
};

// Create new agent
export interface AgentResponse {
  assistant_id: string;
  name: string;
  role: string;
  skill: Skill[];
  general_knowledge: string;
  personality: { [key: string]: number };
  voice_id: string;
  highlight: string;
  gender: string;
  profile_image_url?: string;
}

export const createNewAgent = async (agentData: NewAgentData): Promise<AgentResponse> => {
  try {
    const response = await api.post<AgentResponse>('/api/assistants/create-new', agentData);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create new agent');
  }
};


// Fetch all phone numbers
export const fetchAllPhoneNumbers = async (): Promise<PhoneNumber[]> => {
  try {
    const response = await api.get<PhoneNumber[]>('/api/phone-numbers');
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching all phone numbers');
  }
};

// Assign phone number
export const assignPhoneNumber = async (phoneNumberId: number, assistantId: string): Promise<void> => {
  try {
    await api.post('/api/phone-numbers/assign', { phoneNumberId, assistantId });
  } catch (error) {
    handleError(error, 'Error assigning phone number');
  }
};

// Unassign phone number
export const unassignPhoneNumber = async (phoneNumberId: number): Promise<void> => {
  try {
    await api.post('/api/phone-numbers/unassign', { phoneNumberId });
  } catch (error) {
    handleError(error, 'Error unassigning phone number');
  }
};

// Buy phone number
export const buyPhoneNumber = async (request: BuyPhoneNumberRequest): Promise<PhoneNumber> => {
  try {
    const response = await api.post<PhoneNumber>('/api/phone-numbers/create', request);
    return response.data;
  } catch (error) {
    handleError(error, 'Error buying phone number');
  }
};

// Create phone number
export const createPhoneNumber = async (
  name: string,
  assistantId: string
): Promise<PhoneNumber> => {
  try {
    const response = await api.post<PhoneNumber>('/api/assistants/phone-number', {
      name,
      assistantId,
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Error creating phone number');
  }
};

// Update sendEmailSummaryWebhook
export const sendEmailSummaryWebhook = async (
  assistantId: string,
  emailSummaryEnabled: boolean,
  emailSummaryAddress: string
): Promise<void> => {
  try {
    const response = await api.post(config.EMAIL_SUMMARY_WEBHOOK_URL, {
      assistantId,
      emailSummaryEnabled,
      emailSummaryAddress,
    });
    if (response.status !== 200) { // Assuming 200 is success
      throw new ApiError(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    handleError(error, 'Failed to send email summary webhook');
  }
};

// Initiate demo call
export const initiateDemoCall = async (
  assistantId: string, 
  webCallUrl: string, 
  webCallSipUri: string
): Promise<DemoCallResponse> => {
  try {
    const response = await api.post<DemoCallResponse>(
      `/api/assistants/${assistantId}/demo`, 
      {
        webCallUrl,
        webCallSipUri,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to initiate demo call');
  }
};

// Create user agent
export const createUserAgent = async (agentData: {
  name: string;
  systemContent: string;
  voice: string;
  planId: string;
} ): Promise<UserAgentResponse> => {
  try {
    const response = await api.post<UserAgentResponse>(
      '/api/create-user-agent', 
      agentData
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create user agent');
  }
};

/**
 * Create an XTime Tool.
 * @param dealerCode - The dealer code.
 * @param defaultVin - Optional default VIN.
 * @param defaultOpcode - Optional default opcode.
 * @returns The response containing toolId.
 */
export const createXTimeTool = async (
  dealerCode: string,
  defaultVin?: string,
  defaultOpcode?: string
): Promise<XTimeToolResponse> => {
  try {
    const response = await makeVapiCall<object, XTimeToolResponse>('tool', { // Specify request payload type and response type
      async: false,
      messages: [
        {
          type: 'request-start',
          content: "I'm checking the available service appointment times. This will just take a moment.",
        },
        {
          type: 'request-complete',
          content: "I've found the available appointment times.",
        },
        {
          type: 'request-failed',
          content:
            "I apologize, but I'm unable to retrieve the appointment times right now. There seems to be a technical issue.",
        },
      ],
      type: 'function',
      function: {
        name: 'get_available_time_slots',
        description: 'Retrieves available time slots for service appointments',
        parameters: {
          type: 'object',
          properties: {
            dealerCode: {
              type: 'string',
              description: 'The unique code for the dealership',
            },
            vin: {
              type: 'string',
              description: 'The Vehicle Identification Number',
            },
            opcode: {
              type: 'string',
              description: 'The operation code for the service',
            },
            start: {
              type: 'string',
              description: 'The start date for the appointment search (YYYY-MM-DD)',
            },
            end: {
              type: 'string',
              description: 'The end date for the appointment search (YYYY-MM-DD)',
            },
          },
          required: ['dealerCode', 'vin', 'opcode'],
        },
      },
      server: {
        timeoutSeconds: 30,
        url: `${config.DB_URL}/api/xtime/available-time-slots`,
        secret: config.XTIME_SERVER_SECRET,
      },
      inputValues: {
        dealerCode,
        vin: defaultVin || '',
        opcode: defaultOpcode || '',
        start: '2024-01-01', // Replace with actual start date as needed
        end: '2024-12-31',   // Replace with actual end date as needed
      },
    });

    return response; // Now, response is correctly typed as XTimeToolResponse
  } catch (error) {
    handleError(error, 'Failed to create xTime tool');
    throw error; // Ensure the function throws an error to maintain the Promise's rejection
  }
};

/**
 * Assign a tool to an assistant.
 * @param assistantId - The assistant's ID.
 * @param toolId - The tool's ID.
 * @param toolConfig - The configuration for the tool.
 * @returns The response indicating success or failure.
 */
export const assignToolToAssistant = async (
  assistantId: string, 
  toolId: string, 
  toolConfig: ToolConfig
): Promise<AssignToolResponse> => {
  try {
    const response = await makeVapiCall<{ toolId: string; config: ToolConfig }, AssignToolResponse>(
      `/api/assistants/${assistantId}/tools`, 
      { toolId, config: toolConfig }
    );
    return response;
  } catch (error) {
    handleError(error, 'Failed to assign tool to assistant');
    throw error; // Ensure the function throws an error
  }
};