// src/components/agent_builder_basic/AgentBuilderBasic.tsx

import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
  Drawer,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { motion } from 'framer-motion';
import TemplateSelectionBasic from './TemplateSelectionBasic';
import BasicInfoBasic from './BasicInfoBasic';
import VoiceSelectionBasic from './VoiceSelectionBasic';
import SkillBuilderBasic from './SkillBuilderBasic';
import ReviewBasic from './ReviewBasic';
import ProgressTracker, { steps } from './ProgressTracker';
import SaveDraftButton, { loadSavedDraft } from './SaveDraftButton';
import AgentSummaryPanel from './AgentSummaryPanel';
import { AgentData, AgentTemplate, NewAgentData } from '@/types/AgentTypes';
import PhoneNumberAssignmentBasic from './PhoneNumberAssignmentBasic';
import { createAgent } from '@/api/assistant';
import { fetchUserProfile } from '@/api/user';
import { useAuth } from '../AuthContext';
import PersonalityBuilder from './PersonalityBuilder';
import MenuIcon from '@mui/icons-material/Menu';

const AgentBuilderBasic: React.FC = () => {
  console.log("AgentBuilderBasic component is rendering");
  const theme = useTheme();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  // State Hooks
  const [activeStep, setActiveStep] = useState(0);
  const [agentData, setAgentData] = useState<AgentData>({
    name: '',
    role: '',
    generalKnowledge: '',
    skills: [],
    personality: {},
    highlight: '',
    voiceId: '',
    voiceSampleUrl: '',
    assistantId: '',
    profileImageUrl: '',
    organizationId: '', // Initialize as empty string
    gender: '',
  });
  const [isBuilding, setIsBuilding] = useState(false);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Simulate loading delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // Load saved draft if available
  useEffect(() => {
    const savedDraft = loadSavedDraft();
    if (savedDraft) {
      setAgentData(savedDraft);
    }
  }, []);

  // Update organizationId when user data is available
  useEffect(() => {
    if (user && user.organizationId) {
      setAgentData((prevData) => ({ ...prevData, organizationId: user.organizationId }));
    }
  }, [user]);

  // Early return for loading state
  if (!user || isLoading) {
    return (
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  // Toggle sidebar visibility
  const handleToggleSidebar = () => {
    setIsSidebarOpen((prevOpen) => !prevOpen);
  };

  // Navigate to the next step
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Navigate to the previous step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Handle template selection and preserve organizationId
  const handleTemplateSelect = (template: AgentTemplate) => {
    setAgentData((prevData) => ({
      ...prevData,
      ...template,
      organizationId: user?.organizationId || prevData.organizationId, // Preserve organizationId
      skills: template.skill || [],
      personality: template.personality || {},
      profileImageUrl: template.profileImageUrl || '',
    }));
    handleNext();
  };

  // Update agentData state with new data
  const handleAgentDataChange = (newData: Partial<AgentData>) => {
    setAgentData((prevData) => ({ ...prevData, ...newData }));
  };

  // Handle the creation of the agent
  const handleCreateAgent = async () => {
    setIsBuilding(true);
    try {
      let organizationId = agentData.organizationId;

      if (!organizationId) {
        const userProfile = await fetchUserProfile();
        organizationId = userProfile.organizationId;
      }

      // Validate required fields with detailed messages
      if (!organizationId) {
        alert('Organization ID is missing.');
        return;
      }
      if (!agentData.name) {
        alert('Agent name is required.');
        return;
      }
      if (!agentData.role) {
        alert('Agent role is required.');
        return;
      }

      // Construct NewAgentData object
      const newAgentData: NewAgentData = {
        organizationId: organizationId, // Ensured to be string
        name: agentData.name,
        role: agentData.role,
        skill: agentData.skills || [],
        generalKnowledge: agentData.generalKnowledge || '',
        personality: agentData.personality || {},
        voiceId: agentData.voiceId || '',
        highlight: agentData.highlight || '',
        gender: agentData.gender || '',
        profileImageUrl: agentData.profileImageUrl || '',
      };

      // API call to create a new agent
      const createdAgent = await createAgent(newAgentData);

      // Update agentData with the new assistantId
      setAgentData((prevData) => ({
        ...prevData,
        assistantId: createdAgent.assistantId,
      }));

      // Move to the Phone Number Assignment step
      setActiveStep(6);
    } catch (error) {
      console.error('Error creating agent:', error);
      alert('An error occurred while creating the agent. Please try again.');
    } finally {
      setIsBuilding(false);
    }
  };

  // Complete the setup process
  const handleCompleteSetup = () => {
    setIsSetupComplete(true);
    localStorage.removeItem('agentDraft');
  };

  // Final confirmation step
  const FinalStep: React.FC = () => (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Agent Setup Complete!
      </Typography>
      <Typography variant="body1" paragraph>
        You have successfully created and set up your agent. Click the button below to finish the process.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleCompleteSetup} size="large">
        Complete Setup
      </Button>
    </Box>
  );

  // Determine which component to render based on the current step
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <TemplateSelectionBasic onSelect={handleTemplateSelect} />;
      case 1:
        return <BasicInfoBasic agentData={agentData} onChange={handleAgentDataChange} />;
      case 2:
        return (
          <PersonalityBuilder
            agentData={agentData}
            onChange={handleAgentDataChange}
            onNext={handleNext}
          />
        );
      case 3:
        return <VoiceSelectionBasic agentData={agentData} onChange={handleAgentDataChange} />;
      case 4:
        return <SkillBuilderBasic agentData={agentData} onChange={handleAgentDataChange} />;
      case 5:
        return (
          <ReviewBasic
            agentData={agentData}
            onCreateAgent={handleCreateAgent}
            isBuilding={isBuilding}
          />
        );
      case 6:
        return (
          <PhoneNumberAssignmentBasic
            agentData={agentData}
            onChange={handleAgentDataChange}
            onNext={handleNext}
          />
        );
      case 7:
        return <FinalStep />;
      default:
        return null;
    }
  };

  // Display a congratulatory message upon completion
  if (isSetupComplete) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Congratulations!
        </Typography>
        <Typography variant="body1" paragraph>
          Your agent has been successfully set up and is ready to use.
        </Typography>
        <Button variant="contained" color="primary" href="/" size="large" sx={{ mt: 2 }}>
          Go to Homepage
        </Button>
      </Box>
    );
  }

  // Main rendering of the multi-step form
  return (
    <Container
      maxWidth="lg"
      sx={{
        px: 4,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.default',
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        {/* Progress Tracker */}
        <Box sx={{ mt: 6, mb: 6 }}>
          <ProgressTracker activeStep={activeStep} />
        </Box>

        {/* Step Content */}
        <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={3} sx={{ flex: 1 }}>
            <Grid item xs={12} sx={{ height: '100%', overflow: 'auto' }}>
              {getStepContent(activeStep)}
              {/* Navigation Buttons */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <SaveDraftButton agentData={agentData} />
                {activeStep > 0 && (
                  <Button onClick={handleBack}>Back</Button>
                )}
                {activeStep < steps.length - 1 && activeStep !== 5 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={
                      (activeStep === 1 && (!agentData.name || !agentData.role)) ||
                      false
                    }
                  >
                    Next
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Sidebar Toggle Button */}
        <Box
          sx={{
            position: 'fixed',
            top: theme.spacing(10),
            right: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <IconButton onClick={handleToggleSidebar} color="primary">
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Sidebar Drawer */}
        <Drawer
          anchor="right"
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="temporary"
          sx={{
            '& .MuiDrawer-paper': {
              width: { xs: '80%', sm: '300px' },
              marginTop: '64px',
              height: 'calc(100% - 64px)',
            },
          }}
        >
          <AgentSummaryPanel agentData={agentData} />
        </Drawer>
      </motion.div>
    </Container>
  );
};

export default AgentBuilderBasic;