// VoiceSelection component in src/components/agent_builder/VoiceSelection.tsx

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
} from '@mui/material';
import { motion } from 'framer-motion';
import { AgentData } from '@/types/AgentTypes';
import { fetchVoicesFromDB } from '@/api/voice';
import { DBVoice } from '@/types/VoiceTypes';

interface VoiceOption {
  id: string;
  name: string;
  description: string;
}

interface VoiceSelectionProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

const VoiceSelection: React.FC<VoiceSelectionProps> = ({ agentData, onChange }) => {
  const [voices, setVoices] = useState<VoiceOption[]>([]);
  const [playingAudio, setPlayingAudio] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    const loadVoices = async () => {
      try {
        const voicesData: DBVoice[] = await fetchVoicesFromDB();
        const formattedVoices: VoiceOption[] = voicesData.map((dbVoice) => ({
          id: dbVoice.id,
          name: dbVoice.name,
          description: dbVoice.description || 'No description available',
        }));
        setVoices(formattedVoices);
      } catch (error) {
        console.error('Error fetching voices:', error);
      }
    };
    loadVoices();
  }, []);

  const handleVoiceSelect = (voiceId: string) => {
    onChange({ voiceId: voiceId });
  };

  const handlePlaySample = (voiceName: string) => {
    if (playingAudio) {
      playingAudio.pause();
    }
    const sampleUrl = `/assets/voice-samples/${voiceName}.mp3`;
    const audio = new Audio(sampleUrl);
    audio.play();
    setPlayingAudio(audio);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Voice Selection
      </Typography>
      <Grid container spacing={3}>
        {voices.map((voice, index) => (
          <Grid item xs={12} sm={6} md={4} key={voice.id}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Card
                variant={agentData.voiceId === voice.id ? 'outlined' : 'elevation'}
                sx={{
                  borderColor: agentData.voiceId === voice.id ? 'primary.main' : 'transparent',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h6">{voice.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {voice.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={() => handlePlaySample(voice.name)}>
                    Play Sample
                  </Button>
                  <Button size="small" onClick={() => handleVoiceSelect(voice.id)}>
                    Select Voice
                  </Button>
                </CardActions>
                {agentData.voiceId === voice.id && (
                  <Box sx={{ p: 1, bgcolor: 'primary.main', color: 'primary.contrastText' }}>
                    <Typography variant="body2" align="center">
                      Selected
                    </Typography>
                  </Box>
                )}
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </motion.div>
  );
};

export default VoiceSelection;