// src/components/charts/MonthlyMinutesChart.tsx

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface MonthlyMinutesChartProps {
  monthlyData: { day: string; minutes: number }[];
}

const MonthlyMinutesChart: React.FC<MonthlyMinutesChartProps> = ({ monthlyData }) => {
  return (
    <Card sx={{ width: '100%', marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Monthly Minutes
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={monthlyData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="minutes"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              name="Minutes"
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default MonthlyMinutesChart;
